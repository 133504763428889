<template>
  <el-container style="height: 720px; border: 1px solid #eee">
    <el-header style="text-align: right; font-size: 12px">
      <el-col :md="2">
        <ul class="el-menu--horizontal el-menu">
          <li class="el-menu-item">
            <a href="/exam" style="text-decoration-line: none">
              <img src="@/assets/img/icon/logo.png" class="el-avatar--circle el-avatar--medium" alt="img">
              exam
            </a>
          </li>
        </ul>
      </el-col>
      <el-dropdown>
        <img
          :src="user.avatarUrl"
          class="el-avatar--circle el-avatar--medium"
          style="margin-right: 10px; margin-top: 15px"
          alt=""
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-s-platform"
            class="size"
            @click.native="goToHome"
          >主站</el-dropdown-item>
          <el-dropdown-item
            icon="el-icon-error"
            class="size"
            @click.native="goToLogout"
          >退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu
          :default-active="this.$route.path"
          router
          class="el-menu-vertical-demo"
          :unique-opened="true"
        >
          <el-submenu index="/exam/my">
            <template slot="title">
              <i class="el-icon-user" />
              <span slot="title">我的考试</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/exam/list">
                <i class="el-icon-film" />
                <span slot="title">考试列表</span>
              </el-menu-item>
              <el-menu-item index="/exam/score">
                <i class="el-icon-film" />
                <span slot="title">我的成绩</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/exam/question">
            <template slot="title">
              <i class="el-icon-user" />
              <span slot="title">考试管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/exam/subject">
                <i class="el-icon-film" />
                <span slot="title">科目管理</span>
              </el-menu-item>
              <el-menu-item index="/exam/question">
                <i class="el-icon-film" />
                <span slot="title">试题管理</span>
              </el-menu-item>
              <el-menu-item index="/exam/paper">
                <i class="el-icon-film" />
                <span slot="title">试卷管理</span>
              </el-menu-item>
              <el-menu-item index="/exam/mark">
                <i class="el-icon-film" />
                <span slot="title">阅卷管理</span>
              </el-menu-item>
              <el-menu-item index="/exam/statistics">
                <i class="el-icon-film" />
                <span slot="title">成绩统计</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { getAuthedUser } from '@/utils/auth'

export default {
  mixins: [userMixin],
  data() {
    return {
      user: null
    }
  },
  created() {
    document.title = '考试系统'
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
    }
  }
}
</script>

<style>
</style>
